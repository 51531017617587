.payment-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.payment-input-label {
  color: #757575;
  font-size: 0.9rem;
  font-weight: 700;
}

.payment-input-error {
  color: #FA755A;
  font-size: 0.9rem;
}
