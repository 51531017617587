.ui.button.add-item-container {
    border: 1px dashed #757575;
    background: none;
    font-family: Jost;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c5c5c;
}

.ui.button.add-item-container:hover {
    background: #eaeaed;
}

.ui.button.add-item-container:focus {
    background: none;
    border: 1px solid black;
}

.add-item-container {
    border: 1px dashed #757575;
    cursor: pointer;
    height: 52px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 48px; 
    margin-top: 20px;
  }

.add-item-text {
    font-family: Jost;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c5c5c;
}

.collection-container {
    margin-bottom: 20px
}

.div[disabled], form[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.ui.form.collection-item{
    border: solid 1px #e1e2e5;
    border-radius: 4px;
    margin-bottom: 10px;
}
  
.ui.form.collection-item .item-title{
    cursor: pointer;
    background: #fbfbfb;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: solid 1px #e1e2e5;
}