.ticketing-header-container {
    display: flex;
    justify-content: space-between;
}

.ticketing-header-container .action {
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
}

.ticket-action-container {
    margin-top: 15px;
}

.edit-sale-button {
    height: 44px;
}

.ticketing-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.sale-detail .ticketing-header { 
    margin: 30px 15px 15px 15px !important;
}

.view-detail .ticketing-header { 
    margin: 0px !important;
}

.ticket-details-section {
    width: 100%;
}

.ticketing-side-section {
    margin-top: 0px;
    margin-left: 15px;
    min-width: 300px;
}