.ResourcesContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ResourcesHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ResourcesList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ResourcesListItem {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}

.ResourcesListItemAside {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  min-width: 0;
}
.ResourcesListItemImage {
  width: 8rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ResourcesListItemImg {
  width: 100%;
  height: auto;
}
.ResourcesListItemPlaceholder {
  box-sizing: border-box;
  min-width: 0;
  width: 8rem;;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #f0f0f0;
  padding: 1rem;
}
.ResourcesListItemEdit { 
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  color: #70cadb;

  &:hover {
    filter: brightness(85%);
  }
}

.ResourcesListItemContent {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}
.ResourcesListItemTitle {
  font-size: 1.25rem;
}
.ResourcesListItemDescription {
  font-size: 1rem;
}
.ResourcesListItemLink {
  font-size: 1rem;
  word-wrap: break-word;
}

