.backoffice-model-container {
    display: flex;
    flex-direction: column;
}

.backoffice-model-container div {
    margin-top: 20px;
    margin-bottom: 10px;
}

.backoffice-model-container button {
   width: fit-content;
   margin: 20px auto 0px auto !important;
}