
/* Merchandise */

.Merchandise {
  display: flex;
  flex-direction: column;
}

/* Merchandise Item */

.MerchandiseItem {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  border: 1px solid #ddd;
  gap: 1rem;
}

.MerchandiseItemImage {
  width: 10rem;
  min-height: 6rem;
  height: auto;
}

.MerchandiseItemEditButton { }

.MerchandiseItemDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.MerchandiseItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Merchandise Sku */

.SkuItem {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  gap: 2rem;
  padding: 0.5rem 2rem;
}

.SkuItemTitle {
  flex: 1;
}

.SkuItemPrice {}

.SkuItemQuantity {}


/* Merchandise Item Edit */

.MerchandiseItemEdit {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #ddd;
  gap: 1rem;
}
.MerchandiseItemHeader {
  padding: 1rem;
  border: 1px solid #ddd;
  border-bottom: 0;
}

.MerchandiseItemEditImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.MerchandiseItemButtonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;
}


/* Merchandise Sku Edit */

.SkuHeader {
  border-bottom: 1px solid #ddd;
  margin: 1rem 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.SkuItemHeader {
  display: grid;
  grid-template-columns: auto 8rem 8rem 2rem;
  align-items: center;
  gap: 0 1rem;
}
.SkuItemEdit {
  display: grid;
  grid-template-columns: auto 8rem 8rem 2rem;
  align-items: flex-start;
  gap: 1rem;
}
.SkuButtonDelete {
  margin-top: 0.75rem;
}

.SkuButtonBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1rem 0;
}

/* Merchandise Modal */

.ModalHeader {
  padding: 1rem !important;
  padding-bottom: 0 !important;
}

.ModalContent {
  padding: 1rem !important;
  padding-top: 0 !important;
}

.ModalDescription{
  margin: 1rem 0 !important;
}

.ModalButtons {
  display: flex;
  justify-content: flex-end;
}
