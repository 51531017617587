
/* Merchandise List Item */

.List {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;

  &:last-child {
    border-bottom: 0;
  }
}

.Item {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  gap: 1rem;
  border-bottom: 1px solid #ddd;
}

/* Sidebar */

.Sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.ItemImage {
  width: 10rem;
  min-height: 6rem;
  height: auto;
}

.EditButton { 
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  color: #70cadb;

  &:hover {
    filter: brightness(85%);
  }
}

/* Details */

.ItemDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
}

.ItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ItemTitle {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0.25rem 0;
  text-decoration: underline;
}

.Description {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.25rem 0;
}

/* SKUs */

.SkuItem {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  gap: 2rem;
  padding: 0.5rem 2rem;
}

.SkuItemTitle {
  flex: 1 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.SkuItemPrice {
  flex-shrink: 0;
}

.SkuItemQuantity {
  flex-shrink: 0;
}

