
.app-logo-container {
  position: absolute;
  left: 20px;
  top: 20px;
}

.app-logo {
  height: 25px;
}

.app-header {
  background-color: white;
  height: 65px;
  padding: 0px;
  color: black;
  text-align: right;
  border-bottom: 1px solid #e1e2e5;
}

.app-title {
  font-size: 1.5em;
}

.app-intro {
  margin: 65px 128px 128px 128px;
}

@keyframes app-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
