.amount-container .amount-list-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.amount-container .amount-list-container .amount-item-container {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px;
    padding-right: 2px;
    background: #eee;
    border-radius: 4px;
}

.amount-container .amount-item-container .amount-item-title i{
    cursor: pointer;
    margin-left: 5px;
}

.amount-container {
    margin-bottom: 1.71428571rem;
}

.amount-global-container p {
    margin-bottom: 5px;
}