.stripe-url-modal {
    width: 575px  !important;
}

.stripe-url-modal .header {
    text-align: center;
    color: #5c5c5c !important;
    padding-bottom: 0px !important;
}

.stripe-url-modal .content {
    text-align: center;
}

.stripe-url-modal .sub-content {
    font-weight: bold;
    margin-top: 10px;
}

.stripe-url-modal .text-area-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.stripe-url-modal textarea {
    resize: vertical;
    max-height: 250px;
}

.stripe-url-modal .text-area-container label {
    text-align: left;
    color: #5c5c5c;
    font-weight: bold;
}

.stripe-link-section {
    caret-color: transparent;
    display: flex;
    margin-top: 10px;
}

.stripe-link-section .item {
    margin-right: 10px;
    cursor: pointer;
    color: #757575;
}

.stripe-link-section span {
    text-decoration: underline;
}

/* need to ovverride the css via important due to semantic already having it as important :( */
.stripe-link-section-copy-link {
    background-color: #5c5c5c !important;
    color: #fff !important;
    border-radius: 10px !important;
    height: 35px !important;
    line-height: 15px !important;
    font-size: 12px !important;
}

.stripe-link-section-copy-link::before {
    display: none !important;;
}

textarea {
    padding: 1em;
}
