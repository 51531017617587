.tap-n-donate-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tap-n-donate-header .ui.button {
    height: 44px;
}

.assign-device-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assign-device-form .field {
    height: 75px;
}

.assign-device-button {
    margin-top: 10px !important;
}

.add-device-modal .ui.form.terminal-input-form {
    padding: 15px;
    border: 1px solid rgb(234,234,237);
    background-color: rgb(249,249,250);
    border-radius: 4px;
    margin-bottom: 20px;
}

.device-close-icon, .device-close-icon:hover {
    background-color: transparent !important;
}

.add-device-modal .device-list-container {
    max-height: 350px;
    overflow: auto;
}

.add-device-modal .device-item-container {
    margin-bottom: 5px;
}

.add-device-modal .device-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid rgb(234,234,237);
    background-color: rgb(249,249,250);
    border-radius: 4px;
}

.add-device-modal .device-item p {
    margin: 0px;
    margin-left: 10px;
}

.add-device-modal .device-item .options {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.add-device-modal .device-item .options .Links {
    margin-bottom: 0px;
}

.device-input .ui.form.row .field:first-child{
    padding-right: 4px;
}

.device-input .ui.form.row .field:last-child{
    padding-left: 4px;
}

.device-input-button {
    width: 100px;;
}

.device-input .ui.form.row {
    display: flex;
    justify-content: space-between;
}

.terminal-tab-group {
    margin: 0px;
    border-right: 0px
}

