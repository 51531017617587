.post-save .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.post-save .link-content {
    text-align: center;
}

.post-save .content img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 20px;    
}

.post-save .save-modal-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.post-save .save-modal-error .message{
    margin: 0.4em 0!important;
}