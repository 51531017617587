.space-container {
    margin: 10px 0px;
}

.bulk-upload-container {
    display: inline-block;
}

.bulk-uploaded-picture {
    width: 106px;
}

.bulk-upload-with-remove-container {
    position: relative;
    float:left;
    margin:5px;
}

.remove-file-icon {
    position:absolute;
    display:block;
    top:0;
    left:82px;
    z-index: 2;
    cursor: pointer;
}