@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://fonts.googleapis.com/css?family=Jost:400,700');

body {
  margin: 0;
  padding: 0;
}

/* FROM ZEPLIN */

/* Color palette */

:root {
  --gr-blue: #70cadb;
  --gr-lightest-grey: #e1e2e5;
  --gr-darkest-grey: #5c5c5c;
  --gr-red: #db3030;
  --gr-green: #56b68b;
  --gr-dark-grey: #757575;
  --gr-light-grey: #afb0b3;
  --gr-orange: #ff7044;
}

/* Text styles */

h1 {
  font-family: Jost;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  color: #5c5c5c;
  color: var(--gr-darkest-grey);
}

h2 {
  font-family: Jost;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #5c5c5c;
  color: var(--gr-darkest-grey);
}

body {
  font-family: Jost;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  color: #5c5c5c;
  color: var(--gr-darkest-grey);
}

.Buttons-dark {
  font-family: Jost;
  font-size: 15px;
  line-height: 1.33;
  text-align: center;
  color: #757575;
  color: var(--gr-dark-grey);
}

.Label-style {
  font-family: Jost;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: #5c5c5c;
  color: var(--gr-darkest-grey);
}

.Helper-copy {
  font-family: Jost;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #757575;
  color: var(--gr-dark-grey);
}

.Links {
  font-family: Jost;
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  color: var(--gr-blue);
  margin-bottom: 4px;
}

.Links:hover {
  text-decoration: none;
  color: #33b3cb !important;
}

.SMALL-CAPS {
  font-family: Jost;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: left;
  color: #5c5c5c;
  color: var(--gr-darkest-grey);
}

.grecaptcha-badge {
  left: 20px;
  width: 70px !important;
}

/* Add some basic styling */
.snackbar-container {
  position: fixed;
  top: 65px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

