
.MerchandiseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.MerchandiseHeaderText {
  margin: 0 !important;
}

.MerchandiseHeaderButton {
  margin: 0;
}
