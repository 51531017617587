.rich-text-editor-wrapper {
    border: solid 1px #e1e2e5;
}

.rich-text-editor {
    max-height: 450px;
    padding: 10px;
    border-top: solid 1px #e1e2e5;
}

/* TODO: review why indent and outdent are disabled all the time */
.rich-text-editor-toolbar .rdw-option-wrapper[title='Indent'],
.rich-text-editor-toolbar .rdw-option-wrapper[title='Outdent'] {
    display: none;
}


.rich-text-editor-toolbar .rdw-option-wrapper[aria-selected='true'] {
    background-color: #e1e2e5;
}

.rich-text-editor-toolbar a, 
.rich-text-editor-toolbar a:hover {
    color: #757575;
}
  