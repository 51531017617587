
.ResourceUpload {
  box-sizing: border-box;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  background-color: #f0f0f0;
  padding: 1rem;
}
.ResourceUploadClose {
  cursor: pointer;
  box-sizing: border-box;
  min-width: 0;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1rem;
}
.ResourceUploadContent {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.ResourceUploadImage {
  box-sizing: border-box;
  min-width: 0;
  width: 4rem;
  height: auto;
  margin: auto 0;
}
.ResourceUploadTable {
  box-sizing: border-box;
  min-width: 0;
}
.ResourceUploadFileLink {
  box-sizing: border-box;
  min-width: 0;
  cursor: pointer;
  color: #70cadb;
  word-break: break-all;
  
  &:hover {
    filter: brightness(85%);
  }
}
.ResourceUploadTableHeader {
  font-weight: 600;
  padding-right: 1rem;
  min-width: 6rem;
}




/*
  Thumnail image
*/

.ResourceThumbnail {
  box-sizing: border-box;
  min-width: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.ResourceThumbnailPlaceholder {
  box-sizing: border-box;
  min-width: 0;
  width: 8rem;;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  background-color: #f0f0f0;
  padding: 1rem;
}

.ResourceThumbnailImage {
  box-sizing: border-box;
  min-width: 0;
  width: 8rem;
  height: auto;
}

.ResourceThumbnailText {
  box-sizing: border-box;
  min-width: 0;
  padding: 0.25rem 0.5rem;
  width: 8rem;
  color: #70cadb;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  
  &:hover {
    filter: brightness(85%);
  }
}
