.add-about-element-container span {
  font-weight: bold;
}

.field > span.input-sub {
  margin-bottom: 0.5em;
  display: block;
}

.custom.item-content {
  padding: 1em;
  width: 80%;
}

.collection-item-title-wrapper {
  display: flex;
  justify-content: space-between;
  height: 44px;
  background: #fbfbfb;
  border-bottom: solid 1px #e1e2e5;
  align-items: center;
}

/* important are here so that I can overwrite over so many specificity classnames */
.collection-item-title-wrapper .item-title {
  color: #5c5c5d;
  font-size: 1.07142857rem;
  border: 0 !important;
  padding: 0 !important;
  margin: 0px !important;
  padding-left: 10px !important;
}

.collection-item-title-wrapper > button.icon-button {
  background: none;
  border: none;
  height: 44px;
}

.ui.message.last-potential-payment {
  justify-content: center;
  border-radius: 12px;
}

.ui.message.last-potential-payment .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.message.last-potential-payment p {
  margin-bottom: 0;
}

.ticketing-donation-checkbox {
  margin-bottom: 1rem !important;
}