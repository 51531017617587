.register-container {
    max-width: 568px;
    padding-top: 64px;
    margin: 0 auto;
}

.register-container .grassrootz-logo {
    text-align: center;
}

.register-container .register-inner-container {
    margin: 32px 16px;
    box-shadow: inset 0 0 0 1px #e1e2e5;
    border-radius: 4px;
    padding: 48px;
}

.template-content-wrapper.register .template-content-inner{
    padding: 0;
    margin: 0;
    border: none;
}

.template-content-wrapper.register .template-content-inner .form .container>.field {
    margin: 1rem 0;
}

.template-content-wrapper .template-content-inner .template-heading {
    padding: 0px 24px;
    text-align: center;
}

.save-button-container {
    margin-top: 24px;
}

.save-button-container .ui.button {
    width: 100%
}

.copyright-container {
    text-align: center;
    margin-bottom: 32px;
}