.authentication-frame {
  margin-top: 150px;
  text-align: center;
}

.authentication-frame > div {
  display: inline-block;
  text-align: initial;
  width: 300px;
}

.authentication-error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: #ffd2d2;
  border-bottom: 1px solid #f55c5c;
}

.hide-me {
  -moz-animation: opacityToZeroAnimation 0.6s ease-in 4s forwards;
  -webkit-animation: opacityToZeroAnimation 0.6s ease-in 4s forwards;
  -o-animation: opacityToZeroAnimation 0.6s ease-in 4s forwards;
  animation: opacityToZeroAnimation 0.6s ease-in 4s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes opacityToZeroAnimation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes opacityToZeroAnimation {
  to {
    opacity: 0;
  }
}

.login-container {
  margin-bottom: 32px;
  margin-top: 16px;
}

.login-button {
  margin-top: 32px !important;
}

/* changed class name as semantic also has a css class called 'divider' which was being affected */
.authentication-divider {
  margin-bottom: 20px;
  opacity: 0.3;
}
