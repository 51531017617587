.web-tracking__input-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.ui.form .web-tracking__input-field > .field {
  margin: 0;
  flex: 1;
}


.web-tracking__description {
  margin: 0;
}

.web-tracking__content-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.ui.message.web-tracking__ids-in-use {
  display: flex;
  background-color: #EEF8FB;
  box-shadow: 0px 0px 0px 1px rgba(112, 202, 219, 0.6) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.ui.message.web-tracking__ids-in-use > .icon {
  color: rgba(112, 202, 219, 0.6);
}
