.react-datepicker__calendar-icon {
  width: 1.5rem;
  height: 1.5rem;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.react-datepicker__input-container input {
  padding-left: 3rem !important;
}

.react-datepicker__close-icon {
  padding: 0 1rem 0 0;
}
