.bulk-upload {
  padding: 1.75rem !important;
  display: flex;
  background-color: #EEF8FB !important;
}

.bulk-upload .button-container {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bulk-upload .button-container .upload-button {
  width: 100%;
  padding: 16px 22px;
  margin: 0;
}

.bulk-upload .text-container {
  max-width: 66%;
}
