.remove-image-button-container {
    position: relative;
}

.remove-image-button {
    display: block;
    position: absolute;
    top: 17px;
    cursor: pointer;
    z-index: 2;
    color: #ffffff;
}