
.Label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.25rem 0;
}

.LabelIcon {
  display: inline-block;
  margin-left: 0.25rem;
}

.LabelRequired {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.25rem 0;

  &::after {
    margin: 0;
    margin-left: 0.25rem;
    content: '*';
    color: #db3030;
  }
}

.ErrorLabel {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.25rem 0;
  color: #db3030;
}