.formbuilder-field-description {
    font-size: 14px;
    color: #CACACA;
}
.formbuilder-field-required {
    margin: 0 .2em 0 0;
    content: '*';
    color: #db3030;
}

div.react-datepicker-wrapper, div.react-datepicker__input-container {
    width: 200px;
}

.formbuilder-address-link {
    font-size: 14px;
    cursor: pointer;
}
