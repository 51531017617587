.add-ticket-container {
    border: 1px dashed #757575;
    cursor: pointer;
    height: 52px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 48px; 
    margin-top: 20px;
  }

.add-ticket-text {
    font-family: Jost;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c5c5c;
}

.tikcets-container {
    margin-bottom: 20px
}
