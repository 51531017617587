.add-about-element-container {
    border: 1px dashed #757575;
    cursor: pointer;
    height: 52px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 48px; 
    margin-top: 20px;
  }

.add-about-element-text {
    font-family: Jost;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c5c5c;
}

.about-elements-container {
    margin-bottom: 20px
}

.ui.form.about-element {
    border: solid 1px #e1e2e5;
    border-radius: 4px;
    margin-bottom: 10px;
}
  
.ui.form.about-element .about-element-title-container{
    margin: 0;
}
  
.ui.form.about-element .about-element-title-label{
    cursor: pointer;
    background: #fbfbfb;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: solid 1px #e1e2e5;
 }
  
.ui.form.about-element .about-element-content{
   padding: 1rem;
}

.pdf-upload-inner-container {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.pdf-uploaded-picture {
    width: 106px;
}

.pdf-upload-inner-container .select-image-text {
    margin-left: 20px;
}

.pdf-upload-with-remove-container {
    position: relative;
    float: left;
    margin: 5px;
}

.pdf-upload-info-container {
    padding: 8px 26px;
}

.pdf-remove-file-icon {
    position: absolute;
    display: block;
    top: 0;
    left: 90px;
    z-index: 2;
    cursor: pointer;
}

.image-gallery-upload-with-remove-container {
    position: relative;
    float: left;
    margin: 5px;
}

.image-gallery-uploaded-picture {
    width: 106px;
    height: 106px;
    -o-object-fit: cover;
    object-fit: cover;
}

.image-gallery-remove-file-icon {
    position: absolute;
    display: block;
    top: 0;
    left: 82px;
    z-index: 2;
    cursor: pointer;
}

.image-gallery-container .dropzone {
    width: 106px;
    padding: 0px;
    border: unset;
}

.image-gallary-inner-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
}

.image-gallary-inner-container .select-image-text {
    margin-left: 30px;
}
