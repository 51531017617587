.ui.message.info-box {
  display: flex;
  background-color: #EEF8FB;
  box-shadow: 0px 0px 0px 1px rgba(112, 202, 219, 0.6) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.ui.message.info-box.center {
  justify-content: center;
}

.ui.message.info-box > .icon {
  color: rgba(112, 202, 219, 0.6);
  margin-right: 1.25rem;
}

.ui.message.info-box .header {
  line-height: 22px;
}
