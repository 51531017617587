.managed-grid .grid-options {
	padding-bottom: 2em;
}

.managed-grid .grid-options:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.managed-grid .grid-options .additional-actions {
    margin-right: auto;
}

.grid p.sort-header {
	cursor: pointer;
}

.outer {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.overlay {
    background: #fcfcfc;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    position: absolute;
}

.no-data {
    background: #fcfcfc;
    height: 476px;
    font-family: Jost;
    font-size: 20px;
    font-weight: bold;
}

.middle-table {
    display: table;
    width: 100%;
    height: 100%;
}

.middle-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.table-body {
    min-height: 540px;
    background-color: #fcfcfc
}

.center-panel {
    float: left;
    width: 100%;
}

.sort-icon.active {
    color: #f58236 !important;
    font-size: .8em !important;
}

.sort-active {
    font-weight: bold;
}

.number-formatter {
    text-align: right;
}

.currency-formatter {
   text-align: right;
}

.badge-formatter {
    color: #f4f4f4;
    text-align: center;
    padding: 4px 6px;
    border-radius: 4px;
}
